import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,

  form: {
    ...StandardForm.form,

    questions: {
      purposeOfCustomerRelationship: {
        focused: true,
        section: 'engagement',
        type: 'longtext',
        label: 'Hvad er dit formål med at være kunde i banken?',
        placeholder:
          'Hvad bruger du {name} til? (Indtast minimum 10 karakterer)',
        required: true,
        minLength: 10,
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      cashDepositComment: {
        section: 'cash',
        type: 'longtext',
        label: 'Beskriv årsag til kontante indsættelser',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      annualIncomeComment: {
        section: 'income',
        type: 'longtext',
        label: 'Beskriv hvilke andre indtægter',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            annualIncomeOther: {
              expression: 'equals',
              value: 'Yes'
            }
          }
        },
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      cashWithdrawalComment: {
        section: 'cash',
        type: 'longtext',
        label: 'Beskriv årsagen til valgte interval for kontante hævninger',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      assetsComment: {
        section: 'income',
        type: 'longtext',
        label: 'Hvor stammer din formue fra?',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      foreignAssociatedCountriesComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv tilknytning til udlandet',
        required: true,
        dependencies: {
          type: 'every',
          conditions: {
            foreignAssociatedCountries: {
              expression: 'not_includes',
              value: ['']
            }
          }
        },
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      foreignReceiveComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv årsag til modtagelse af udenlandske overførsler',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      },
      foreignTransferComment: {
        section: 'abroad',
        type: 'longtext',
        label: 'Beskriv årsag til udenlandske overførsler',
        required: true,
        minLength: 10,
        placeholder: 'Indtast minimum 10 karakterer',
        /* matches when string contains atleast 10 char,
      numbers or letter regardless of posistion in the string*/
        pattern: /(?:.*?[a-zA-Z0-9\s*]){10}.*/
      }
    }
  },
  responseFormat: {
    ...StandardForm.responseFormat,
    purposeAndScope: {
      ...StandardForm.responseFormat.purposeAndScope,
      annualCashDeposit: {
        amount: values => values.cashDepositAmount[0],
        comment: values =>
          values.cashDepositComment
            ? values.cashDepositComment
            : 'Kunden svarede ’Ingen’ – kommentar ikke påkrævet'
      },
      annualCashWithdrawal: {
        amount: values => values.cashWithdrawalAmount[0],
        comment: values =>
          values.cashWithdrawalComment
            ? values.cashWithdrawalComment
            : 'Kunden svarede ’Ingen’ – kommentar ikke påkrævet'
      },
      transfersFromAbroad: {
        amount: values => values.foreignReceiveAmount[0],
        comment: values =>
          values.foreignReceiveComment
            ? values.foreignReceiveComment
            : 'Kunden svarede ’Ingen’ – kommentar ikke påkrævet'
      },
      transfersAbroad: {
        amount: values => values.foreignTransferAmount[0],
        comment: values =>
          values.foreignTransferComment
            ? values.foreignTransferComment
            : 'Kunden svarede ’Ingen’ – kommentar ikke påkrævet'
      }
    }
  }
};

export default mergeObjects(StandardForm, customizations);
