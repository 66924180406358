import { BankConfig } from '../typings/types';
import { theme } from '@wco/toolkit';

const config: BankConfig = {
  name: 'Nordfyns Bank',
  bankId: 8,
  logoWidth: 250,
  color: '#0039ac',
  googleAnalytics: {
    test: 'UA-40429949-2',
    production: 'UA-21988252-2'
  },
  forceOverride: true,
  backgroundGradient: [
    theme.colors.brand.tertiaryColor,
    theme.colors.brand.tertiaryColor
  ],
  backgroundImage: '',
  topBar: { background: theme.colors.brand.primaryColor, color: '#fff' },
  body: { headlineColor: theme.colors.brand.primaryColor, bodyColor: '#000' }
};

export default config;
